import React from 'react';

const SelectService = (props) => {
    const { serviceOptions, selectServiceHandler } = props;
    return (
        <>
            <div className={`ml-3 d-flex align-items-center`}>
                <div className={`mr-2`}>Service:</div>
                <select
                    className={`filter_module_group`}
                    name="selectedLabel"
                    onChange={selectServiceHandler}
                >
                    {serviceOptions.map((item, index) => (
                        <option key={`select_service_${index}`} value={item['_id']}>{item.name}</option>
                    ))}
                </select>
            </div>
            <style>
                {`
                    .filter_module_group {
                        height: 32px;
                        border-radius: 4px;
                        border: 1px solid #cfcfd4;
                    }
                    .filter_module_group:hover {
                        border-color: #3a3abf;
                        cursor: pointer;
                    }
            
                    .filter_module_group:focus {
                        outline: none;
                    }
                `}
            </style>
        </>
    );
}

export default SelectService;