import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
// import ls from "local-storage";

// component
// import Header from "../../../components/componentHeader/header";
// import DatePicker from "../../../components/module/datePicker";
import DateRangePicker from "../../../components/DatePicker/index";
import SelectCategory from "../../../components/module/selectCategory";
import SelectService from "../../../components/module/selectservice";
import Currency from "../../../components/module/currency";
import SelectStatus from "../../../components/module/selectStatus";
import PrinAndExport from "../../../components/printAndExport/printAndExport";
import TopNPromoTable from "./topNPromoTable";
import moment from "moment";

// fixtures
import {
  countries,
  defaultSelectedCurrency,
} from "../../../fixtures/dummyApi/dummyApi";
import { serviceOptions } from "../../../fixtures/service/service";
import { statusDropdown } from "../../../fixtures/status/status";

// api
import { getTopNPromoCodeApi } from "../../../services/topNPromoCode";
import { storeIdApiCall } from "../../../services/getStoreId";

// scss import
import {
  container_class,
  filter_module,
  view_activity,
} from "./topNPromo.module.scss";

const defaultStoreData = [{ _id: 0, name: "--No Data--" }];

const TopNPromo = (props) => {
  const history = useHistory();
  const componentRef = useRef();

  // to handle date manipulation
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") != null
    ? localStorage.getItem("startDate")
    : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"))
  
  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") != null
    ? localStorage.getItem("endDate")
    : moment().format("YYYY-MM-DD HH:mm:ss"))

  // to handle currency manipulation
  const [filteredCountries, setFilteredCountries] = useState([...countries]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    defaultSelectedCurrency
  );
  const [currencyModuleAnchor, setCurrencyModuleAnchor] = useState(null);

  // service
  const [selectedService, setSelectedService] = useState(0);

  // select sort by drop down
  const [sortBy, setSortBy] = useState(1);

  // status
  const [selectedStatus, setSelectedStatus] = useState(1);

  // to handle store category manipulation
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [storeData, setStoreData] = useState([...defaultStoreData]);

  // api response
  const [topNLoading, setTopNLoading] = useState(true);
  const [topNError, setTopNError] = useState(true);
  const [topNTableResponse, setTopNTableResponse] = useState([]);

  // api store id
  const [storeIdLoading, setStoreIdLoading] = useState(true);
  const [storeIdError, setStoreIdError] = useState("failure");

  useEffect(() => {
    setTopNLoading(true)
    getTopNPromoData(sortBy);
  }, [startDate, endDate, selectedCurrency, selectedService, selectedStoreId]);

  const getTopNPromoData = (sortBy) => {
    getTopNPromoCodeApi(
      startDate,
      endDate,
      selectedCurrency,
      selectedService,
      sortBy,
      selectedStoreId
    )
      .then((res) => {
        setTopNLoading(false);
        setTopNError("success");
        setTopNTableResponse(res.data.data);
      })
      .catch((err) => {
        setTopNLoading(false);
        setTopNError("failure");
        console.log("getPromoCodeApi-top", err);
      });
  };

  useEffect(() => {
    getStoreId();
  }, []);

  // get store id from api function
  const getStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        setStoreIdLoading(false);
        setStoreIdError("success");
        setStoreData(res.data.data);
      })
      .catch((err) => {
        setStoreIdLoading(false);
        setStoreIdError("failure");
        console.log("getPromoCodeApi-id", err);
      });
  };

  // handle date picker function
  const handleDatePicker = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  };

  // handle currency function
  const handleSelectCurrency = (symbol, currency) => {
    setTopNLoading(true)
    setSelectedCurrency({ symbol, currency });
    setCurrencyModuleAnchor(null);
  };

  // handle select category function
  const selectCategoryHandler = (event) => {
    const { value } = event.target;
    setSelectedStoreId(value);
  };

  // handle select service function
  const selectServiceHandler = (event) => {
    const { value } = event.target;
    setSelectedService(value);
  };

  const selectStatuseHandler = (event) => {
    // setTopNLoading(true)
    const { value } = event.target;
    setSelectedStatus(value);
  };

  const sortByFuntion = (event) => {
    setTopNLoading(true)
    const { value } = event.target;
    setSortBy(value);
    getTopNPromoData(value);
  };

  return (
    <React.Fragment>
      <div className="containerDiv">
        <div className="">
          <div
            className={`px-3 pb-2 border bg-white ${container_class}`}
            ref={componentRef}
          >
            {/* link to dashboard */}
            <div
              className={`globalFontSize`}
              style={{cursor:'pointer'}}
              onClick={() => {
                // ls.remove("endDate");
                // ls.remove("startDate");
                return history.push("/dashboard/overview")}}>
                <i className="fas fa-angle-left mr-2"></i>Reports
                </div>

            <h4>Top N Promo</h4>

            {/* print and export */}
            <PrinAndExport componentRef={componentRef} />

            <div className={`d-flex justify-content-between ${filter_module}`}>
              <div className={`d-flex globalFontSize`}>

              {/* date picker component */}
              <div className="d-flex align-items-center">
                <label className="mr-2 mb-0">Date Range:</label>
                <DateRangePicker
                  getGroupBy={true}
                  handleDateChange={handleDatePicker}
                />
              </div>                 

                {/* currency select component */}
                <Currency
                  selectedCurrency={selectedCurrency}
                  currencyModuleAnchor={currencyModuleAnchor}
                  selectCurrency={handleSelectCurrency}
                  filteredCountries={filteredCountries}
                  setCurrencyModuleAnchor={(anchor) =>
                    setCurrencyModuleAnchor(anchor === "" ? null : anchor)
                  }
                />
              </div>

              <div className={`d-flex globalFontSize`}>
                {/* status component */}
                <SelectStatus
                  statusDropdown={statusDropdown}
                  selectStatuseHandler={selectStatuseHandler}
                />

                {/* serivce component */}
                <SelectService
                  serviceOptions={serviceOptions}
                  selectServiceHandler={selectServiceHandler}
                />

                {/* store category component */}
                <SelectCategory
                  storeData={storeData}
                  selectCategoryHandler={selectCategoryHandler}
                />
              </div>
            </div>
          </div>

          <TopNPromoTable
            loading={topNLoading}
            sortByFuntion={sortByFuntion}
            error={topNError}
            tableData={topNTableResponse}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopNPromo;
