import React, { useEffect, useState } from "react";
import ls from "local-storage";
// components
import PromoAnalysisTable from "./PromoAnalysisTable/promoAnalysisTable";
import TopNPromo from "./TopNPromo/topNPromo";
import DateRangePicker from "../../../components/dateRangePicker/dateRangePicker";
import SelectCategory from "../../../components/module/selectCategory";
import SelectService from "../../../components/module/selectservice";
import Currency from "../../../components/module/currency";
import SelectStatus from "../../../components/module/selectStatus";
import moment from "moment";
import Headers from './header/index'
// fixtures
import {
  countries,
  defaultSelectedCurrency,
} from "../../../fixtures/dummyApi/dummyApi";
import { serviceOptions } from "../../../fixtures/service/service";
import { statusDropdown } from "../../../fixtures/status/status";
import "./Overview.scss";
import "./global-font-css.css";

// api
import {
  getPromoCodeAnalysisAPi,
  getTopNPromoApi,
} from "../../../services/promoCodeDashboard";
import { storeIdApiCall } from "../../../services/getStoreId";

// scss import

const defaultStoreData = [{ _id: 0, name: "--No Data--" }];

function PromoCodeDashboard(props) {
  
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") != null
    ? localStorage.getItem("startDate")
    : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"))
  
  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") != null
    ? localStorage.getItem("endDate")
    : moment().format("YYYY-MM-DD HH:mm:ss"))
    
  
  // to handle currency manipulation
  const [filteredCountries, setFilteredCountries] = useState([...countries]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    defaultSelectedCurrency
  );
  const [currencyModuleAnchor, setCurrencyModuleAnchor] = useState(null);

  // service
  const [selectedService, setSelectedService] = useState(0);

  // status
  const [selectedStatus, setSelectedStatus] = useState(1);

  // top n promo sort drop down
  const [sortBy, setSortBy] = useState("Revenue");
  const [SortTopNPromoAnchor, setSortTopNPromoAnchor] = useState("");

  // to handle store category manipulation
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [storeData, setStoreData] = useState([...defaultStoreData]);

  // api response for promo usage analysis
  const [loading, setLoading] = useState(true);
  const [errorTableDataResponse, setErrorTableDataResponse] = useState(
    "failure"
  );
  const [tableDataResponse, setTableDataResponse] = useState([]);

  // api response for top n promo
  const [topNPromoloading, setTopNPromoloading] = useState(true);
  const [errorTopNPromoResponse, setErrorTopNPromoResponse] = useState(
    "failure"
  );
  const [TopNPromoData, setTopNPromoData] = useState([]);

  // api store id
  const [storeIdLoading, setStoreIdLoading] = useState(true);
  const [storeIdError, setStoreIdError] = useState("failure");

  useEffect(() => {
    setLoading(true)
    setTopNPromoloading(true)
    getPromoCodeAnalysisData(
      startDate,
      endDate,
      selectedCurrency,
      selectedService,
      selectedStatus,
      selectedStoreId
    );
    getTopNPromoData(sortBy);
  }, [
    startDate,
    endDate,
    selectedCurrency,
    selectedService,
    selectedStatus,
    selectedStoreId,
  ]);

  useEffect(() => {
    // console.log('selectedCurrency',selectedCurrency)
    ls.set("currency", selectedCurrency.currency)
    getStoreId();
  }, []);

  const getPromoCodeAnalysisData = (
    // dateSelect,
    startDate,
    endDate,    
    selectedCurrency,
    selectedService,
    selectedStatus,
    selectedStoreId
  ) => {
    getPromoCodeAnalysisAPi(
      // dateSelect,
      startDate,
      endDate,      
      selectedCurrency,
      selectedService,
      selectedStatus,
      selectedStoreId
    )
      .then((res) => {
        setLoading(false);
        setErrorTableDataResponse("success");
        setTableDataResponse(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        setErrorTableDataResponse("failure");
        console.log("getPromoCodeApi-check", err);
      });
  };

  const getTopNPromoData = (sortBy) => {
    let sortValue = sortBy === "Revenue" ? 3 : sortBy === "Quantity" ? 1 : 2;
    getTopNPromoApi(
      // dateSelect,
      startDate,
      endDate,      
      selectedCurrency,
      selectedService,
      selectedStatus,
      sortValue,
      selectedStoreId
    )
      .then((res) => {
        setTopNPromoloading(false);
        setErrorTopNPromoResponse("success");
        setTopNPromoData(res.data.data.table);
      })
      .catch((err) => {
        setTopNPromoloading(false);
        setErrorTopNPromoResponse("failure");
        console.log("getPromoCodeApi-check", err);
      });
  };

  // get store id from api function
  const getStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        setStoreIdLoading(false);
        setStoreIdError("success");
        setStoreData(res.data.data);
      })
      .catch((err) => {
        setStoreIdLoading(false);
        setStoreIdError("failure");
        console.log("getPromoCodeApi-id", err);
      });
  };
    
    // handle date picker function
  const handleDatePicker = (startDate, endDate, groupBy, selectedGroupBy) => {
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    localStorage.setItem("selectedGroupBy", JSON.stringify(selectedGroupBy));
    // setDateSelect([startDate, endDate]);
    setStartDate(startDate)
    setEndDate(endDate)
  };

  // handle currency function
  const handleSelectCurrency = (symbol, currency) => {
    ls.set("currency", currency)
    setSelectedCurrency({ symbol, currency });
    setCurrencyModuleAnchor(null);
  };

  // handle select category function
  const selectCategoryHandler = (event) => {
    const { value } = event.target;
    setSelectedStoreId(value);
  };

  // handle select service function
  const selectServiceHandler = (event) => {
    const { value } = event.target;
    setSelectedService(value);
  };

  const selectStatuseHandler = (event) => {
    const { value } = event.target;
    setSelectedStatus(value);
  };

  const sortByFuntion = (sortby) => {
    setTopNPromoloading(true)
    setSortBy(sortby);
    setSortTopNPromoAnchor(null);
    getTopNPromoData(sortby);
  };


  const [groupByName, setGroupByName ] = useState({ value: 0, label: "Hour" })
  const [groupBy, setGroupBy] = useState(0)

  const headerStateHandler = (stateName, stateValue) => {
    if (stateName === "date" && stateValue) {
        setStartDate(stateValue.startDate)
        setEndDate(stateValue.endDate)
    }
    if (stateName === "groupBy" && (stateValue === 0 || stateValue)) {
        setGroupBy(stateValue)
    }
    if (stateName === "groupByName" && stateValue) {
      setGroupByName(stateValue)
    }
  };

 
  return (
    <React.Fragment>
      <div className="overviewWrapper">
        <div className="head">
          <div className="title mb-3">
            <span style={{ color: "black" }}>Promo Code Dashboard</span>
          </div>


          <div className="d-flex justify-content-between align-items-center">
            {/* <div className="mt-2"> */}
              <Headers
                groupBy={groupBy}
                startDate={startDate}
                endDate={endDate}
                headerStateHandler={headerStateHandler}
                groupByName={groupByName}
                // currency={selectedCurrency}

                selectedCurrency={selectedCurrency}
                currencyModuleAnchor={currencyModuleAnchor}
                selectCurrency={handleSelectCurrency}
                filteredCountries={filteredCountries}
                setCurrencyModuleAnchor={(anchor) =>
                  setCurrencyModuleAnchor(anchor === "" ? null : anchor)
                }
              />


          {/* </div> */}

            <div className="d-flex align-items-center globalFontSize">
              {/* status component */}
              <SelectStatus
                statusDropdown={statusDropdown}
                selectStatuseHandler={selectStatuseHandler}
              />

              {/* serivce component */}
              <SelectService
                serviceOptions={serviceOptions}
                selectServiceHandler={selectServiceHandler}
              />

              {/* store category component */}
              <SelectCategory
                storeData={storeData}
                selectCategoryHandler={selectCategoryHandler}
              />
            </div>
          </div>  
        </div>


          <div className={`px-3 d-flex`} style={{ marginTop: "9rem" }}>
          {/*promo code usage dashboard*/}
          <PromoAnalysisTable
            tableData={tableDataResponse}
            loading={loading}
            error={errorTableDataResponse}
            selectedCurrency={selectedCurrency}
          />

          <TopNPromo
            loading={topNPromoloading}
            error={errorTopNPromoResponse}
            tableData={TopNPromoData}
            sortBy={sortBy}
            sortByFuntion={sortByFuntion}
            sortAnchor={SortTopNPromoAnchor}
            selectedCurrency={selectedCurrency}
            toggleAnchor={(anchor) =>
              setSortTopNPromoAnchor(anchor ? anchor : null)
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default PromoCodeDashboard;
