import React, { useEffect, useState, useRef } from "react";
import CouponAnalysisGraph from "./couponAnalysisGraph";
import DateRangePicker from "../../../components/DatePicker/index";
import SelectCategory from "../../../components/module/selectCategory";
import Currency from "../../../components/module/currency";
import { CircularProgress } from "@material-ui/core";
import ReactSelect from "../../../components/reactSelect/reactSelect";


import { getCouponDataApi } from "../../../services/couponAnalysis";
import { storeIdApiCall } from "../../../services/getStoreId";
import PrinAndExport from "../../../components/printAndExport/printAndExport";
import { useHistory } from "react-router-dom";
import { dateTimeFormatForGraph, tooltipFormatter } from "../../../lib/helper";
import moment from "moment";


// fixtures
import {
  countries,
  defaultSelectedCurrency,
} from "../../../fixtures/dummyApi/dummyApi";

import {
  main_tag_data,
  coupon_summary_heading,
  filter_module,
} from "./couponAnalysis.module.scss";

const defaultStoreData = [{ _id: 0, name: "--No Data--" }];

const summaryHeading = [
  "Orders with coupons",
  "Net revenue from coupon orders",
  "percent of orders with coupons",
  "percent revenue from coupon",
];

let title = {
  style: {
    fontSize: "12px",
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: 500,
  },
};
let graphState = {
  options: {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 40,
    },
    title: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    xaxis: { title: { ...title } },
    yaxis: { title: { ...title } },
  },
};

const CouponAnalysis = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("failure");
  const [response, setResponse] = useState({});
  const [summaryData, setSummaryData] = useState([]);

  // to handle date manipulation
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") != null
    ? localStorage.getItem("startDate")
    : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"))
  
  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") != null
    ? localStorage.getItem("endDate")
    : moment().format("YYYY-MM-DD HH:mm:ss"))

  const [filteredCountries, setFilteredCountries] = useState([...countries]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    defaultSelectedCurrency
  );
  const [currencyModuleAnchor, setCurrencyModuleAnchor] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [storeData, setStoreData] = useState([...defaultStoreData]);
  const [storeIdLoading, setStoreIdLoading] = useState(true);
  const [storeIdError, setStoreIdError] = useState("failure");

  const [selected, setSelected] =  useState(localStorage.getItem("selectedGroupBy")
  ? JSON.parse(localStorage.getItem("selectedGroupBy"))
  : { value: 0, label: "Hour" });
  const [selectData,  setSelectData] = useState([{ value: 0, label: "Hour" }]);

  useEffect(() => {
    getCouponData(selected['value']);
  }, [startDate,endDate, selectedCurrency, selectedStoreId]);

  const componentRef = useRef();
  const history = useHistory();

  useEffect(() => {
    getStoreId();
  }, []);

  // useEffect(()=>{
  //   getCouponData(selected['value'])
  // },[selected])

  async function getCouponData(selectedGroupBy) {
    try {
      let response = await getCouponDataApi(
        startDate,
        endDate,
        selectedCurrency,
        selectedStoreId,
        selectedGroupBy
      );
      
      let xCategories = dateTimeFormatForGraph(
        selected.label || 'Hour',
        response.data.data.graph.xaxis.categories
      );
      
      graphState = {
        ...graphState,
        series: [...response.data.data.graph.series],
        options: {
          ...graphState.options,
          title: {
            ...graphState.options.title,
            text: response.data.data.graph.title.text,
          },

          grid: {
            // providing padding in the graph
            padding: {
              left: 50,
              right: 20,
            },
          },

          xaxis: {
            categories: [...xCategories],
            // categories: response.data.data.graph.xaxis.categories,
            title: {
              ...graphState.options.xaxis.title,
              text: response.data.data.graph.xaxis.title.text,
              style: { fontSize: "11px", fontWeight: 700 },
              offsetY:-10
            },
          },
          yaxis: {
            title: {
              ...graphState.options.yaxis.title,
              text: response.data.data.graph.yaxis.title.text,
              style: { fontSize: "11px", fontWeight: 700 },
            },
          },
        },
      };
      setResponse(graphState);
      setLoading(false);
      setError("success");
      let data = response.data.data;
      setSummaryData([
        data.percent_count,
        data.promo_revenue_count,
        data.promo_count,
        data.percent_revenue_count,
      ]);
    } catch (err) {
      setLoading(false);
      setError("failure");
      console.log("coupon-data-api", err);
    }
  }

  // get store id from api function
  const getStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        setStoreIdLoading(false);
        setStoreIdError("success");
        setStoreData(res.data.data);
      })
      .catch((err) => {
        setStoreIdLoading(false);
        setStoreIdError("failure");
        console.log("getPromoCodeApi-id", err);
      });
  };

  // handle date picker function
  const handleDatePicker = (startDate, endDate, groupBy, selectedGroupBy) => {
    setStartDate(startDate);
    setEndDate(endDate)
    
    setSelectData(groupBy)
    setSelected(selectedGroupBy)

    setLoading(true);
    setError("failure");
  };

  // handle currency function
  const handleSelectCurrency = (symbol, currency) => {
    setSelectedCurrency({ symbol, currency });
    setCurrencyModuleAnchor(null);
    setLoading(true);
    setError("failure");
  };

  // handle select category function
  const selectCategoryHandler = (event) => {
    const { value } = event.target;
    setSelectedStoreId(value);
  };

  // group by
  const selectHandler = (option) => {
    setLoading(true);
    getCouponData(option['value']);
    setSelected(option)
  };

  return (
    <React.Fragment>
      <div className="overviewWrapper" ref={componentRef}>
        <div className="head">
          {/* link to dashboard */}
          <div
            className={`globalFontSize`}
            style={{cursor:'pointer'}}
            onClick={() => {
              return history.push("/dashboard/overview")}}>
                <i className="fas fa-angle-left mr-2"></i>Reports
          </div>
          <div className="title">
            <span style={{ color: "black" }}>Coupon Analysis</span>
          </div>
          <PrinAndExport componentRef={componentRef} />

          <div style={{ width: "100%" }}>
            <div
              className={`d-flex justify-content-between ${filter_module}`}
              // style={{ marginTop: "65px" }}
            >
              <div className={`d-flex globalFontSize`}>

              {/* date picker component */}
              <div className="d-flex align-items-center">
                <label className="mr-2 mb-0">Date Range:</label>
                <DateRangePicker
                  getGroupBy={true}
                  handleDateChange={handleDatePicker}
                />
              </div>
              

                {/* group by select component */}
              <div className="d-flex align-items-center">
                <label className="mr-2 mb-0">Group By</label>
                <ReactSelect
                  className={false}
                  city={selectData}
                  change={selectHandler}
                  selected={selected}
                />
              </div>                    

                {/* currency select component */}
                <Currency
                  selectedCurrency={selectedCurrency}
                  currencyModuleAnchor={currencyModuleAnchor}
                  selectCurrency={handleSelectCurrency}
                  filteredCountries={filteredCountries}
                  setCurrencyModuleAnchor={(anchor) =>
                    setCurrencyModuleAnchor(anchor === "" ? null : anchor)
                  }
                />
              </div>

              <div className={`d-flex globalFontSize`}>
                {/* store category component */}
                <SelectCategory
                  storeData={storeData}
                  selectCategoryHandler={selectCategoryHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`mx-3 d-flex flex-wrap justify-content-between`}
          style={{ marginTop: "11rem" }}
        >
          {summaryHeading.map((data, index) => {
            return (
              <div
                key={`coupon_analysis_${index}`}
                className={`px-2 py-1 ${
                  index > 1 ? "mt-5" : ""
                } ${main_tag_data} d-flex justify-content-center `}
              >
                {loading ? (
                    <CircularProgress/>
                ) : error === "failure" ? (
                  <div>No Data</div>
                ) : (
                  <div style={{ position: "relative", width: "100%" }}>
                    <span className={` ${coupon_summary_heading}`}>
                      {summaryHeading[index]}
                    </span>
                    <span style={{ fontSize: "24px" }}>
                      {index === 1 || index === 3
                        
                        ? 
                        `${
                            index === 1 ? selectedCurrency.symbol : ""
                          } ${tooltipFormatter(Number(summaryData[index]).toFixed(2),selectedCurrency.symbol,false )} ${
                            index === 3 ? "%" : ""
                          }`
                        : `${summaryData[index]} ${index === 2 ? "%" : ""}`}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ marginTop: "22rem" }}>
        <CouponAnalysisGraph
          loading={loading}
          error={error}
          response={response}
        />
      </div>
    </React.Fragment>
  );
};

export default CouponAnalysis;
