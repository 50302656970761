import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { CSVLink } from "react-csv";

// components
import PromoCodeTable from "./PromoAnalysisTable";
import DateRangePicker from "../../../components/DatePicker/index";
import SelectCategory from "../../../components/module/selectCategory";
import SelectService from "../../../components/module/selectservice";
import Currency from "../../../components/module/currency";
import SelectStatus from "../../../components/module/selectStatus";
import moment from "moment";

// fixtures
import {
  countries,
  defaultSelectedCurrency,
} from "../../../fixtures/dummyApi/dummyApi";
import { serviceOptions } from "../../../fixtures/service/service";
import { statusDropdown } from "../../../fixtures/status/status";

// api services
import { getPromoCodeUsageAnalysisApi } from "../../../services/promoUsageAnalysis";
import { storeIdApiCall } from "../../../services/getStoreId";

// sccs file import as module
import {
  container_class,
  filter_module,
  containerDiv,
  export_modal_poper,
  export_modal,
} from "./promoCodeAnalysis.module.scss";
import PrinAndExport from "../../../components/printAndExport/printAndExport";

const defaultStoreData = [{ _id: 0, name: "--No Data--" }];

const PromoCodeUsageAnalysis = (props) => {
  const history = useHistory();
  const componentRef = useRef();

  // to handle date manipulation
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") != null
    ? localStorage.getItem("startDate")
    : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"))
    
    const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") != null
    ? localStorage.getItem("endDate")
    : moment().format("YYYY-MM-DD HH:mm:ss"))
  
  // to handle export modal
  const [modal, setModal] = useState(false);

  // to handle currency manipulation
  const [filteredCountries, setFilteredCountries] = useState([...countries]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    defaultSelectedCurrency
  );
  const [currencyModuleAnchor, setCurrencyModuleAnchor] = useState(null);

  // status
  const [selectedStatus, setSelectedStatus] = useState(1);

  // service
  const [selectedService, setSelectedService] = useState(0);

  // export set
  const [exportLoading, setExportLoading] = useState(false);

  // to handle store category manipulation
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [storeData, setStoreData] = useState([...defaultStoreData]);

  // api response
  const [loading, setLoading] = useState(true);
  const [errorTableDataResponse, setErrorTableDataResponse] = useState(
    "failure"
  );
  const [tableDataResponse, setTableDataResponse] = useState([]);

  // api store id
  const [storeIdLoading, setStoreIdLoading] = useState(true);
  const [storeIdError, setStoreIdError] = useState("failure");

  // when component mountes
  useEffect(() => {
    getPromoCodeData();
  }, [
    startDate,
    endDate,
    selectedCurrency,
    selectedService,
    selectedStatus,
    selectedStoreId,
  ]);

  useEffect(() => {
    getStoreId();
  }, []);

  // get promo code usage analysis data function
  const getPromoCodeData = (exportData = "") => {
    getPromoCodeUsageAnalysisApi(
      startDate,
      endDate,
      selectedCurrency,
      selectedService,
      selectedStatus,
      exportData,
      selectedStoreId
    )
      .then((res) => {
        setLoading(false);
        setTableDataResponse(res.data.data);
        setErrorTableDataResponse("success");
        setExportLoading(true);
      })
      .catch((err) => {
        setLoading(false);
        setErrorTableDataResponse("failure");
        console.log("getPromoCodeApi", err);
        setExportLoading(false);
      });
  };

  // get store id from api function
  const getStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        setStoreIdLoading(false);
        setStoreIdError("success");
        setStoreData(res.data.data);
      })
      .catch((err) => {
        setStoreIdLoading(false);
        setStoreIdError("failure");
        console.log("getPromoCodeApi-id", err);
      });
  };

  // detailed api response for each promo
  async function getPromoDetailedData(data) {
    history.push(
      `/dashboard/promo-usage-detailed-analysis/${selectedService}/${selectedStoreId}/${data["promo_id"]}`
    );
  }

  // handle date picker function
  const handleDatePicker = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setLoading(true);
    setErrorTableDataResponse("failure");
  };

  // handle currency function
  const handleSelectCurrency = (symbol, currency) => {
    setSelectedCurrency({ symbol, currency });
    setCurrencyModuleAnchor(null);
    setLoading(true);
    setErrorTableDataResponse("failure");
  };

  // handle select category function
  const selectCategoryHandler = (event) => {
    const { value } = event.target;
    setSelectedStoreId(value);
    // setLoading(true);
    // setErrorTableDataResponse('failure');
  };

  // handle select service function
  const selectServiceHandler = (event) => {
    const { value } = event.target;
    setSelectedService(value);
    setLoading(true);
    setErrorTableDataResponse("failure");
  };

  const selectStatuseHandler = (event) => {
    const { value } = event.target;
    setSelectedStatus(value);
    setLoading(true);
    setErrorTableDataResponse("failure");
  };

  const exportData = () => {
    console.log("check");
    getPromoCodeData("export");
    setModal(true);
    setExportLoading(false);
  };

  return (
    <React.Fragment>
      <div className={`${containerDiv}`}>
        
          <div
            className={`px-3 pb-3 pt-1 border-bottom bg-white ${container_class}`}
          >
            {/* link to dashboard */}
            <div
              className={`globalFontSize`}
              style={{cursor:'pointer'}}
              onClick={() => {
                // ls.remove("endDate");
                // ls.remove("startDate");
                return history.push("/dashboard/overview")
              }}>
                <i className="fas fa-angle-left mr-2"></i>Reports

            </div>
            <h4 className="title">Promo Code Analysis</h4>

            {/* print and export */}
            <PrinAndExport
              componentRef={componentRef}
              exportData={exportData}
            />

            <div className={`d-flex justify-content-between ${filter_module}`}>
              <div className={`d-flex globalFontSize`}>
                
              <div className="d-flex align-items-center">
                <label className="mr-2 mb-0">Date Range:</label>
                <DateRangePicker
                  getGroupBy={true}
                  handleDateChange={handleDatePicker}
                />
              </div>  

                {/* currency select component */}
                <Currency
                  selectedCurrency={selectedCurrency}
                  currencyModuleAnchor={currencyModuleAnchor}
                  selectCurrency={handleSelectCurrency}
                  filteredCountries={filteredCountries}
                  setCurrencyModuleAnchor={(anchor) =>
                    setCurrencyModuleAnchor(anchor === "" ? null : anchor)
                  }
                />
              </div>

              <div className={`d-flex globalFontSize`}>
                {/* status component */}
                <SelectStatus
                  statusDropdown={statusDropdown}
                  selectStatuseHandler={selectStatuseHandler}
                />

                {/* serivce component */}
                <SelectService
                  serviceOptions={serviceOptions}
                  selectServiceHandler={selectServiceHandler}
                />

                {/* store category component */}
                <SelectCategory
                  storeData={storeData}
                  selectCategoryHandler={selectCategoryHandler}
                />
              </div>
            </div>
          </div>

          <div
            className="d-flex align-items-center justify-content-center"
          >
            <PromoCodeTable
              componentRef={componentRef}
              tableData={tableDataResponse}
              loading={loading}
              error={errorTableDataResponse}
              selectedCurrency={selectedCurrency}
              getPromoDetailedData={getPromoDetailedData}
            />

            <Modal
              open={modal && exportLoading}
              onClose={() => setModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className={` ${export_modal_poper}`}
            >
              <div className={`col-12 px-0 ${export_modal}`}>
                <div className="py-3 reportModal-header pl-3 border-bottom">
                  Export your report
                </div>
                <div className="py-3 reportModal-subText pl-3 border-bottom">
                  Report will be exported as a CSV (comma separated values)
                  table.
                </div>
                <div className="py-3 col-12">
                  <div className="row justify-content-end">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => setModal(false)}
                    >
                      Cancel
                    </button>
                    <CSVLink
                      onClick={() => setModal(false)}
                      data={tableDataResponse}
                      filename={"my-file.csv"}
                      // className="reportModal-exportBtn"
                      target="_blank"
                    >
                      <button type="button" className="btn btn-primary mx-3">
                        Export
                      </button>
                    </CSVLink>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
       
      </div>
    </React.Fragment>
  );
};

export default PromoCodeUsageAnalysis;
